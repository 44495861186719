import {
    apiList,
    Api
} from "@/assets/js/api";
import tools from "@/assets/js/tool";
import draggable from "vuedraggable"; //拖拽组件
import sorttable from "@/components/sortTemplate.vue";
import multitime from "@/components/multiTime.vue";
import paging from "@/components/pagings.vue";


// import { axios_file } from "@/assets/js/http_axios";
export default {
    inject: ["reload"],
    components: {
        multitime,
        draggable,
        sorttable,
        paging,
    },
    data() {
        return {
            numbers1: "",
            numbers2: "",
            //   ---------  待签收表单  -----------
            activeName: 'first', //  tab标识
            originalTabelHeadeTitle: [],
            originalTabelHeadeTitle1: [],
            tableHeigth: "80vh", //  表格高度
            isActive: true, //  输入框绑定动态class
            judge: false, //  输入框绑定动态class
            MoreConditions: '更多条件', //  输入框绑定动态class
            pageNum: 1, //  页码
            total: 0,
            sizeList: [10, 20, 50, 100, 200],
            size: 50, //  一页几条
            queryData: {
                endTime: '',
                startTime: '',
                storageId: '',
                isRejection: '',
                checkStatus: '',
                memberId: '',
                expressName: '',
            }, //  代签收列表搜索数据
            cscArr: [], //  快递公司数组
            cangkuList: [], //  仓库数组
            inspectionStatusList: [], //  验货状态列表
            rejectionStatusList: [], //  拒收单拒收状态
            tableData: [], //  表格数据
            claimedData: [],
            totalArr: [], //  需要合计的字段
            tabelHeadeTitle: [{
                name: "仓库",
                field: "storageName",
                width: "80",
                sort: 2,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员名称",
                field: "memberNickname",
                width: "120",
                sort: 3,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员号",
                field: "memberId",
                width: "80",
                sort: 4,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "快递公司",
                field: "expressName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "快递单号",
                field: "courierNumber",
                width: "180",
                sort: 5,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹状态",
                field: "packageStatusShow",
                width: "80",
                sort: 7,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "货物类型",
                field: "itemCategoryName",
                width: "80",
                sort: 7,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "是否垫付快递费",
                field: "loanStatusShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "是否贵重物品",
                field: "isPreciousShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "团号",
                field: "invitationCode",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "认领状态",
                field: "isClaimShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "拒签状态",
                field: "",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "拒收类型",
                field: "rejectionStatusShow",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "拒收要求",
                field: "rejectionComment",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "验货状态",
                field: "checkStatusShow",
                width: "80",
                sort: 10,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "验货要求",
                field: "inspectionComment",
                width: "80",
                sort: 10,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "品名",
                field: "itemName",
                width: "80",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "货值",
                field: "unitPrice",
                width: "80",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "件数",
                field: "count",
                width: "80",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            }, {
                name: "数量",
                field: "itemCount",
                width: "80",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹备注",
                field: "packageComment",
                width: "150",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "新建包裹时间",
                field: "packageCreateTime",
                width: "180",
                sort: 20,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "(无)操作人",
                field: "",
                width: "80",
                sort: 20,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            ], //  代签收默认表头
            myTableHeard: [], //  代签收动态表头
            RejectOnArrival: false, //  到库签收弹出层状态
            modify: false, //  修改件数弹出层状态
            singleData: [], //  表格中选取的数据
            changeCountFD: {
                packageId: "",
                count: "",
            }, //  修改件数表单数据
            viewPictureBox: false, //查看图片
            form: {
                name: '',
            },
            //  -------------- 签收记录 ---------------
            queryDataRecord: {
                courierNumbers: '',
                expressName: '',
                memberId: '',
                signforUserId: '',
                signforStartTime: '',
                signforEndtTime: '',
            }, //  签收记录搜索表单
            tabelHeadeTitle1: [{
                name: "仓库",
                field: "storageName",
                width: "80",
                sort: 2,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员名称",
                field: "memberNickname",
                width: "120",
                sort: 3,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "会员号",
                field: "memberId",
                width: "80",
                sort: 4,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "快递公司",
                field: "expressName",
                width: "120",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "快递单号",
                field: "courierNumber",
                width: "180",
                sort: 5,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹状态",
                field: "packageStatusShow",
                width: "120",
                sort: 7,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹签收时间",
                field: "signforTime",
                width: "180",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "签收人",
                field: "signforUserName",
                width: "80",
                sort: 1,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "包裹件数",
                field: "count",
                width: "80",
                sort: 12,
                isShow: true,
                isTotal: true,
                isSort: true,
            },
            {
                name: "货物类型",
                field: "itemcategoryName",
                width: "80",
                sort: 7,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            {
                name: "品名",
                field: "itemName",
                width: "80",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "货值",
                field: "unitPrice",
                width: "80",
                sort: 11,
                isShow: true,
                isTotal: false,
                isSort: true,
            },

            {
                name: "数量",
                field: "itemCount",
                width: "80",
                sort: 12,
                isShow: true,
                isTotal: true,
                isSort: true,
            },

            {
                name: "包裹备注",
                field: "comment",
                width: "180",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            }, {
                name: "是否有图片",
                field: "hasPic",
                width: "80",
                sort: 15,
                isShow: true,
                isTotal: false,
                isSort: true,
            },
            ], //  表格默认表头
            myTableHeard1: [], //  动态表头
            totalArr1: [], //  需要合计的字段
            pageStart: 1, //  页码
            pageCount: 0,
            pageTotal: 50,
            //  取消签收
            cencanQxForm: {},
            expressNumArr: [],
            loading: false,
            expressNum: '',
            editData: {},
            // ======公用组件==========
            show_sortTableHeard: false, //显示弹窗
            show_sortTableHeard1: false, //显示弹窗
            show_upload: false, //显示上传弹窗
            selectLength: 0, //显示的长度
            selectLength1: 0, //显示的长度
            allCheck: false, //全选
            src: '', //查看图片
            allCheck1: false, //全选
            className: "", //导出需要
            className1: "", //导出需要
            fieldName: [], //下载字段名
            head: [], //下载表头字段
            fileList: [],
            uploadUrl: "", //上传路径
            sortName: '模板名', //排序名
            sortName1: '模板名', //排序名
            pageGroupName: 'daiqianshou', //页面标识
            fullscreen: false,
            TableStyle: true,
            FormData: { //签收
                count: '1', //件数 必填
                courierNumber: '', //快递单号 必填
                sign: '', //	签名密匙 必填
                expressName: '', //快递公司
                memberId: '', //会员号
                storageId: '', //仓库ID
            },
            CancelSignInBox: false, //取消签收弹框
            // ======公用组件==========
            saoMa: '',
            btnType: false,
            timeTypes: [ //  时间类型
                {
                    value: '0',
                    label: '新建包裹时间'
                },
            ],
            timeType: '0', //  时间类型
            timeTypes_b: [ //  时间类型
                {
                    value: '0',
                    label: '签收时间'
                },
            ],
            timeType_b: '0', //  时间类型
            startTime_a: '',
            endTime_a: '',
            startTime_b: '',
            endTime_b: '',

        };
    },
    updated() {
        if (this.show_sortTableHeard) {
            // 排序表头
            let arr = this.tabelHeadeTitle;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle = arr;
        }
        if (this.show_sortTableHeard1) {
            // 排序表头
            let arr = this.tabelHeadeTitle1;
            for (var i = 0; i < arr.length; i++) {
                arr[i].sort = i + 1;
            }
            this.tabelHeadeTitle1 = arr;
        }

    },
    created() {
        this.uploadUrl = this.common.uploadUrl;
        this.myTableHeard1 = this.tabelHeadeTitle;
        if (this.originalTabelHeadeTitle.length == 0) {
            this.originalTabelHeadeTitle = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle)
            );
        }
        if (this.originalTabelHeadeTitle1.length == 0) {
            this.originalTabelHeadeTitle1 = JSON.parse(
                JSON.stringify(this.tabelHeadeTitle1)
            );
        }
        this.getGroupFindFieldSort(); //  获取用户自定义表头
        this.getStorageList(); //  仓库列表
        this.getCscArr(); //  获取快递公司
        this.getStatusValList('bill_inspection_v1.check_status') //  获取验货状态
        this.getStatusValList('bill_rejection_v1.handle_status') //  获取拒签状态

        // this.myTableHeard = this.tabelHeadeTitle;
        this.endTime_a = tools.getFlexDate(); //今天的时间;
        this.startTime_a = tools.getFlexDate(-10); //10天前
        this.endTime_b = tools.getFlexDate(); //今天的时间;
        this.startTime_b = tools.getFlexDate(-10); //10天前
    },
    beforeDestroy() {

    },
    mounted() {
        this.getData();
        this.btnType = this.Tools.isDaiGou() || false;
        this.timeTypes = this.timeTypes
    },
    activated() {

    },

    watch: {
        saoMa(newwVal) {
            this.FormData.courierNumber = newwVal
        },
    },
    methods: {
        rowclick(row, event, column) {
            this.$refs.mytable.toggleRowSelection(row);
        },
        rowclick2(row, event, column) {
            this.$refs.mytable1.toggleRowSelection(row);
        },
        // 多时间组件(待签收)
        queryBtn_time(s, e) {
            this.startTime_a = s || ''
            this.endTime_a = e || ''
        },
        timestatus(type, s, e) {
            this.timeType = type || '0'
            this.startTime_a = s || ''
            this.endTime_a = e || ''
        },
        // 多时间组件(签收记录)
        queryBtn_time_b(s, e) {
            this.startTime_b = s || ''
            this.endTime_b = e || ''
        },
        timestatus_b(type, s, e) {
            this.timeType = type || '0'
            this.startTime_b = s || ''
            this.endTime_b = e || ''
        },


        more() { //  输入框绑定动态class
            this.isActive = !this.isActive
            this.judge = !this.judge
            if (this.judge) {
                this.MoreConditions = '收起条件'
            } else {
                this.MoreConditions = '更多条件'
            }
            this.fetTableHeight();
        },
        tableRowClassName({
            row,
            rowIndex
        }) { //  表格样式i
            if (rowIndex % 2 === 0) {
                return "warning-row";
            }
        },
        getData() { // 获取数据
            let nidlist = tools.getOrderNum(this.numbers1)
            this.queryData.courierNumber = nidlist
            this.queryData.pageStart = this.pageNum
            this.queryData.pageTotal = this.size;
            let endTime = '';
            let startTime = '';
            if (this.endTime_a) {
                endTime = this.endTime_a + ' 23:59:59'
            }
            if (this.startTime_a) {
                startTime = this.startTime_a + ' 00:00:00'
            }
            let param = JSON.parse(JSON.stringify(this.queryData))
            param.endTime = endTime
            param.startTime = startTime
            Api.signforList(param).then((res) => {
                if (res.data.status === "success") {
                    this.className = res.data.result.className || "";
                    this.tableData = res.data.result.data || [];
                    this.total = res.data.result.pageCount || 0;
                    this.fetTableHeight();
                }
            });
        },

        getCscArr() { //  获取快递公司数组
            Api.expressChooseList().then((res) => {
                if (res.data.status === "success") {
                    this.cscArr = res.data.result
                } else {
                    console.log(`${res.data.message}--快递公司数组`)
                }
            });
        },


        getStorageList() { //  获取仓库下拉列表
            Api.getStorageList().then((res) => {
                this.cangkuList = res.data.result || [];
            });
        },

        getStatusValList(status) { // 获取状态展示值
            let param = {
                tableAndFieldName: status,
            };
            Api.getStatusValList(param).then((res) => {
                if (status === "package_v1.package_status") { // 包裹状态
                    this.packageStatusList = res.data.result || [];
                }
                if (status === "bill_rejection_v1.handle_status") { // 拒收单拒收状态
                    this.rejectionStatusList = res.data.result || [];
                }
                if (status === "bill_inspection_v1.check_status") { // 验货单验货状态
                    this.inspectionStatusList = res.data.result || [];
                }
            });
        },
        getSignforRecordList() { // 获取签收记录
            let nidlist = tools.getOrderNum(this.numbers2)
            this.queryDataRecord.courierNumbers = nidlist
            let params = JSON.parse(JSON.stringify(this.queryDataRecord));
            params.pageStart = this.pageStart
            params.pageTotal = this.pageTotal;
            let endTime = '';
            let startTime = '';
            if (this.endTime_b) {
                endTime = this.endTime_b + ' 23:59:59'
            }
            if (this.startTime_b) {
                startTime = this.startTime_b + ' 00:00:00'
            }
            params.signforEndtTime = endTime
            params.signforStartTime = startTime
            // params.endTime = endTime
            // params.startTime = startTime

            Api.signforRecordList(params).then((res) => {
                if (res.data.status === "success") {
                    this.claimedData = res.data.result.data || [];
                    this.className1 = res.data.result.className || "";
                    this.pageCount = res.data.result.pageCount || 0;
                    this.fetTableHeight();
                }
            });
        },

        queryBtn_ok(num) { // 查询按钮
            if (num === 0) {
                this.pageNum = 1;

                this.getData();

            } else {
                this.pageStart = 1
                this.getSignforRecordList()
            }
        },
        resetData(num) { // 搜索框表单重置
            switch (num) {
                case 1:
                    this.queryData = {}
                    this.getData()
                    this.numbers1 = ""
                    this.startTime_a = ''
                    this.endTime_a = ""
                    break
                case 2:
                    this.queryDataRecord = {
                        courierNumbers: '',
                        expressName: '',
                        memberId: '',
                        signforUserId: '',
                        signforStartTime: '',
                        signforEndtTime: '',
                    }
                    this.startTime_b = ''
                    this.endTime_b = ""
                    this.getSignforRecordList()
                    this.numbers2 = ""
                    break
            }
        },

        InspectionHandling() { //  新建验收

            this.$router.push({
                path: '/BusinessManagement/Warehousing/InspectionHandling',
                query: {
                    pageType: 1,

                }
            })

        },

        ModifiedQuantity() { //  修改件数
            if (this.singleData.length === 1) {
                this.changeCountFD.packageId = this.singleData[0].packageId
                this.changeCountFD.oldCount = this.singleData[0].count
                this.modify = true
            } else {
                this.$message.warning('请选择一条数据')
            }
        },
        closebut() {
            this.closebut = false
            this.changeCountFD = {
                packageId: "",
                count: "",
            }
        },
        changeCountFn() { //  修改件数提交数据
            delete this.changeCountFD.oldCount
            let param = {
                packageId: this.changeCountFD.packageId,
                count: this.changeCountFD.count,
            }
            let sign = tools.getSign(param);
            param.sign = sign
            Api.updateSignPackageCount(param).then((res) => {
                if (res.data.status === 'success') {
                    this.$message.success(res.data.message)
                    this.modify = false
                    this.getData()
                } else {
                    this.$message.error(`${res.data.message}--提交`)
                }
            })
        },

        handleSelectionChange(e) { // 表格选择事件1
            this.singleData = e
        },

        daokuqianshou() { //  到库签收按钮
            // if (this.singleData.length !== 1) {
            //     this.$message.warning('请勾选一条数据');
            //     return false
            // }
            if (this.singleData.length == 1) {
                this.FormData.courierNumber = this.singleData[0].courierNumber
                this.FormData.expressName = this.singleData[0].expressName
                this.FormData.memberId = this.singleData[0].memberId
                this.FormData.storageId = this.singleData[0].storageId
                this.FormData.count = this.singleData[0].count

            }
            this.RejectOnArrival = true


        },
        // 批量签收
        daokuqianshou_pl() {
            if (this.singleData.length < 1) {
                this.$message.warning('至少要勾选一条数据');
                return false
            }
            this.$confirm('是否要批量签收 ' + this.singleData.length + ' 个包裹?', '提示:').then(() => {
                let ids = [];
                this.singleData.forEach((item, index) => {
                    ids.push(item.packageId)
                })
                let param = {
                    packageIds: ids
                };
                let sign = tools.getSign(param);
                param.sign = sign
                if (this.btnType) {
                    Api.bmg_batchSignPackage(param).then(res => {
                        if (res.data.status == 'success') {
                            this.$message.success(res.data.message || '操作成功');
                            this.getData();
                        }

                    })
                } else {
                    Api.batchSignPackage(param).then(res => {
                        if (res.data.status == 'success') {
                            this.$message.success(res.data.message || '操作成功');
                            this.getData();
                        }

                    })
                }


            }).catch(() => { })
        },
        SignFor() { //  到库签收 post
            let parm = {
                courierNumber: this.FormData.courierNumber,
                expressName: this.FormData.expressName,
                memberId: this.FormData.memberId,
                storageId: this.FormData.storageId,
                count: this.FormData.count,
            }
            let sign = tools.getSign(parm);
            parm.sign = sign
            console.log('this.btnType')
            console.log(this.btnType)
            if (this.btnType) {
                // 八米购
                Api.bmg_signPackage(parm).then((res) => {
                    console.log(res.data)
                    if (res.data.status === 'success') {
                        this.$message.success("签收成功")
                        this.RejectOnArrival = false
                        this.getData()
                    }
                })
            } else {
                // 集运没有代购哦
                Api.signPackage(parm).then((res) => {
                    console.log(res.data)
                    if (res.data.status === 'success') {
                        this.$message.success("签收成功")
                        this.RejectOnArrival = false
                        this.getData()
                    }
                })
            }

        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },


        //取消签收
        CancelSignIn() {
            if (this.singleData.length < 1) {
                this.CancelSignInBox = true
            } else {
                this.$confirm(`此操作将取消签收该${this.singleData.length}条数据, 是否继续?`, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let param = {
                        packageIds: []
                    }
                    this.singleData.forEach(item => {
                        param.packageIds.push(item.packageId)
                    })
                    param.sign = tools.getSign(param)
                    this.qxqs(param)
                })
            }
        },

        qxqs(data) { //  取消签收接口
            Api.cancelSign(data).then((res) => {
                this.$message.success(res.data.message)
                this.getSignforRecordList()
            });
        },


        remoteMethod(query) { //  select框远程搜索
            if (query !== '') {
                this.loading = true;
                Api.getExpressNumData({
                    courierNumber: query
                }).then((res) => {
                    if (res.data.status === 'success') {
                        this.loading = false
                        this.expressNumArr = res.data.result
                    }
                });
            } else {
                this.expressNumArr = [];
            }
        },
        SE(val) { //  快递单号下拉框选中函数
            this.editData = val
            this.cencanQxForm.cencanQx = val.courierNumber
        },
        cqx() {
            this.qxqs(this.editData.packageId)
        },

        //查看图片
        viewPicture() {
            let singleData = this.singleData
            if (singleData.length != 1) {
                this.$message.warning("请勾选一条数据查看");
            } else {

                if (singleData[0].picVo != null) {
                    console.log(singleData[0].picVo[0].url)
                    this.viewPictureBox = true
                    this.src = singleData[0].picVo[0].url || ''
                } else {
                    this.$message.warning("无图片");
                }
            }
        },

        handleCommandTabs(m) {
            if (m == "a") {
                this.myModel()
            } else if (m == 'b') {
                this.exportBtn()
            } else if (m == 'c') {
                this.exportTemplateDownload()
            } else if (m == 'd') {
                this.upLoadBtn()
            } else {
                this.screen()
            }

        },
        rejection() {
            this.$router.push("/BusinessManagement/Warehousing/RejectionTreatment");
        },
        //添加包裹
        AddPackage() {
            this.$router.push("/BusinessManagement/addPacks");
        },
        //付款确认
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    alert("submit!");
                } else {
                    return false;
                }
            });
        },
        //上传图片
        handleRemove(file, fileList) {
            //console.log(file, fileList);
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url;
            this.dialogVisible = true;
        },


        //导出操作
        handleCommand_dc(command) {
            if (command == 'dc') {
                // 导出
                this.exportBtn()
            } else if (command == 'dcmb') {
                // 下载模板
                this.exportTemplateDownload()
            } else if (command == 'scmb') {
                // 上传模板
                this.upLoadBtn()
            }
        },

        // ===============================================导出部分=开始======
        // 上传导出模板
        uploadSectionFile(param) {
            //console.log(param);
            const file = param.file,
                fileName = file.name;

            // 根据后台需求数据格式
            const form = new FormData();
            // 文件对象
            form.append("file", file);
            // 本例子主要要在请求时添加特定属性，所以要用自己方法覆盖默认的action
            form.append("fileName", fileName);
            let signArr = [{
                key: "fileName",
                val: fileName,
            },];
            let sign = tools.getMD5Sign(signArr);
            form.append("sign", sign);
            // 项目封装的请求方法，下面做简单介绍
            Api.uploadFile(form)
                .then((res) => {
                    if (res.data.status === "success") {
                        let fileName = res.data.result.fileName;

                        let signArr = [{
                            key: "groupName",
                            val: this.pageGroupName,
                        },
                        {
                            key: "templateName",
                            val: this.sortName,
                        },
                        {
                            key: "fileName",
                            val: fileName,
                        },
                        ];
                        let sign_ = tools.getMD5Sign(signArr);
                        Api.saveExportTemplate({
                            groupName: this.pageGroupName,
                            templateName: this.sortName,
                            fileName: fileName,
                            sign: sign_,
                        }).then((res) => {
                            this.$message.success(res.data.message || "更新成功");
                            this.show_upload = false;
                        });
                    }
                })
                .catch((err) => { });
        },

        // 下载用户导出模板
        exportTemplateDownload() {
            var fieldName = this.fieldName;
            var head = this.head;
            if (head.length == 0) {
                let fieldNames = [];
                let heads = [];
                if (this.pageGroupName == 'daiqianshou') {
                    for (var i = 0; i < this.myTableHeard.length; i++) {
                        if (this.myTableHeard[i].isShow) {
                            fieldNames.push(this.myTableHeard[i].field);
                            heads.push(this.myTableHeard[i].name);
                        }
                    }
                }
                if (this.pageGroupName == 'yiqianshou') {
                    for (var i = 0; i < this.myTableHeard1.length; i++) {
                        if (this.myTableHeard1[i].isShow) {
                            fieldNames.push(this.myTableHeard1[i].field);
                            heads.push(this.myTableHeard1[i].name);
                        }
                    }
                }
                fieldName = fieldNames;
                head = heads;
            }
            //加密数组格式
            let signArr = [{
                key: "fieldName",
                val: fieldName,
            },
            {
                key: "head",
                val: head,
            },
            ];
            let sign = tools.getMD5Sign(signArr);
            let param = {
                fieldName: fieldName,
                head: head,
                sign: sign,
            };
            Api.exportTemplateDownload(param).then((res) => {
                let downloadurl = res.data.result.fileUrl || "";
                //下载(这里其实是直接访问)
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();
            });
        },
        // 真正导出
        templateDataExport(customizedExportId) {
            let className
            let that = this;
            let datas = []
            if (this.pageGroupName == 'daiqianshou') {
                datas = that.tableData;
                className = that.className
            } else {
                console.log(that.claimedData)
                datas = that.claimedData
                className = that.className1
            }
            let parm = {
                customizedExportId: customizedExportId, //模板id
                className: className,
                data: datas,
            };
            let signarr = tools.getSignArr(parm);
            let signs_e = tools.getMD5Sign(signarr);
            let array = {
                customizedExportId: customizedExportId,
                className: className,
                data: JSON.stringify(datas),
                sign: signs_e,
            }
            Api.templateDataExport(array).then((res) => {
                //下载(这里其实是直接访问)
                let downloadurl = res.data.result.fileUrl;
                var a = document.createElement("a");
                a.setAttribute("href", downloadurl);
                a.setAttribute("target", "_blank");
                a.click();

            });
        },
        // 上传按钮
        upLoadBtn() {
            this.show_upload = true;
        },
        // 点击导出按钮
        exportBtn() {
            let that = this;
            let fieldName = [];
            let head = [];
            if (this.pageGroupName == 'daiqianshou') {
                for (var i = 0; i < this.myTableHeard.length; i++) {
                    if (this.myTableHeard[i].isShow) {
                        fieldName.push(this.myTableHeard[i].field);
                        head.push(this.myTableHeard[i].name);
                    }
                }
            }
            if (this.pageGroupName == 'yiqianshou') {
                for (var i = 0; i < this.myTableHeard1.length; i++) {
                    if (this.myTableHeard1[i].isShow) {
                        fieldName.push(this.myTableHeard1[i].field);
                        head.push(this.myTableHeard1[i].name);
                    }
                }
            }
            this.fieldName = fieldName;
            this.head = head;

            // 1,先获取用户导出模板
            Api.getUserExportTemplate({
                groupName: this.pageGroupName,
            }).then((res) => {
                console.log(res.data)
                if (res.data.result && res.data.result[0].customizedExportId) {
                    // 有自定义模板

                    let customizedExportId = res.data.result[0].customizedExportId;
                    that.templateDataExport(customizedExportId);


                } else {
                    if (this.pageGroupName == 'daiqianshou') {
                        tools.dynamicExport(
                            this.myTableHeard,
                            this.className,
                            this.tableData,
                            "到库待签收列表"
                        );
                    } else if (this.pageGroupName == 'yiqianshou') {
                        tools.dynamicExport(
                            this.myTableHeard1,
                            this.className1,
                            this.claimedData,
                            "到库已签收列表"
                        );
                    }

                }
            });
        },
        // ===============================================导出部分=结束======

        /**
         * ******************************计算表格高度**开始*************************
         */
        // 重置table高度
        resetHeight() {
            return new Promise((resolve, reject) => {
                this.tableHeigth = 0;
                resolve();
            });
        },
        // 设置table高度
        fetTableHeight() {
            this.resetHeight().then((res) => {
                this.tableHeigth = this.getHeight();
                this.$nextTick(() => {
                    this.$refs.mytable.doLayout();
                });
            });
        },
        // 获取表格高度
        getHeight() {
            let windonHeight = document.body.clientHeight
            let queryHeigth = 50;
            let btnListHeight = this.$refs.btnList.offsetHeight + 16; // 按钮列表  16==上下的margin
            if (this.judge) {
                queryHeigth = this.$refs.queryCriteria.offsetHeight; //查询框
                if (this.activeName === 'second') {
                    // queryHeigth = this.$refs.queryCriteria1.offsetHeight; //查询框
                    queryHeigth = 20 //查询框
                }
            } else {
                queryHeigth = 50
            }
            let pagsHeigth = 60; //分页组件
            var otherHeight = queryHeigth + btnListHeight + pagsHeigth + 60 + 10 + 15;
            return Math.floor(windonHeight - otherHeight) || "50vh"; //
        },
        /**
         * ******************************计算表格高度**结束*************************
         */

        handleClick() { //  顶部标签页切换

            if (this.activeName === 'second') {
                this.pageGroupName = 'yiqianshou'
                this.SignInRecord()
                this.getSignforRecordList()
            } else {
                this.pageGroupName = 'PAGE_DKQS'
                this.getGroupFindFieldSort()
                this.getData()
            }
            this.fetTableHeight();
            this.singleData = []
            this.numbers2 = ""
            this.numbers1 = ""
        },

        SignInRecord() { //  签收记录表头获取
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        this.myTableHeard1 = JSON.parse(arr[0].sortObject);
                        this.sortName1 = arr[0].sortName;
                    }
                } else {
                    this.myTableHeard1 = this.tabelHeadeTitle1;
                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < this.myTableHeard1.length; i++) {
                    if (this.myTableHeard1[i].isTotal) {
                        totalArr.push(this.myTableHeard1[i].field);
                    }
                }
                this.totalArr1 = totalArr;
            });

            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },
        // 获取自定义的排序,没有就用默认的
        getGroupFindFieldSort() {
            Api.getGroupFindFieldSort({
                groupName: this.pageGroupName, //  当前页面表格的标记(自定义字符串)
            }).then((res) => {
                let arr = res.data.result || [];
                if (arr.length > 0) {
                    if (arr[0].sortObject) {
                        this.myTableHeard = JSON.parse(arr[0].sortObject);
                        this.sortName = arr[0].sortName;
                    }
                } else {
                    this.myTableHeard = this.tabelHeadeTitle;

                }
                let totalArr = [];
                //提取需要合计的字段
                for (var i = 0; i < this.myTableHeard.length; i++) {
                    if (this.myTableHeard[i].isTotal) {
                        totalArr.push(this.myTableHeard[i].field);
                    }
                }
                this.totalArr = totalArr;
            });

            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },

        myModel() { //  设置模板按钮
            this.show_sortTableHeard = true;
            this.tabelHeadeTitle = JSON.parse(JSON.stringify(this.myTableHeard));
            let selectLength = 0;
            for (var i = 0; i < this.tabelHeadeTitle.length; i++) {
                if (this.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            this.selectLength = selectLength;
            if (selectLength === this.tabelHeadeTitle.length) {
                this.allCheck = true;
            } else {
                this.allCheck = false;
            }
        },
        //设置模板按钮(签收记录)
        myModel1() {
            let that = this;
            that.show_sortTableHeard1 = true;
            that.tabelHeadeTitle1 = JSON.parse(JSON.stringify(that.myTableHeard1));
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength === that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },


        // 排序==>取消按钮(签收记录)
        cancelSortHeard1() {
            this.show_sortTableHeard1 = false;
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
        },
        // 排序==>确认按钮(签收记录)
        confirmOk1(e) {
            this.tabelHeadeTitle1 = e;
            this.myTableHeard1 = this.tabelHeadeTitle1;
            let sortObject = this.myTableHeard1;
            let signs = [{
                key: "sortName",
                val: this.sortName1, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName1, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard1 = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            this.show_sortTableHeard1 = false;
        },
        // 点击某一项
        checktab1(idx, e) {
            let that = this;
            that.tabelHeadeTitle1[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
            if (selectLength == that.tabelHeadeTitle1.length) {
                that.allCheck1 = true;
            } else {
                that.allCheck1 = false;
            }
        },
        //全选
        checkAll1(e) {
            let that = this;
            this.allCheck1 = e;
            for (var j = 0; j < this.tabelHeadeTitle1.length; j++) {
                this.tabelHeadeTitle1[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle1.length; i++) {
                if (that.tabelHeadeTitle1[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength1 = selectLength;
        },
        // 合计
        getSummaries1(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr1.length; i++) {
                    if (column.property == that.totalArr1[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable1.doLayout();
            });
            return sums;
        },
        //关闭弹窗1
        closeDias1(e) {
            this.show_sortTableHeard1 = e;
        },
        // 排序==>取消按钮
        cancelSortHeard() {
            this.show_sortTableHeard = false;
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
        },
        // 排序==>确认按钮
        confirmOk(e) {
            this.tabelHeadeTitle = e;
            this.myTableHeard = this.tabelHeadeTitle;
            let sortObject = this.myTableHeard;
            let signs = [{
                key: "sortName",
                val: this.sortName, //模板名称
            },
            {
                key: "groupName",
                val: this.pageGroupName, //页面标识
            },
            {
                key: "sortObject",
                val: JSON.stringify(sortObject),
            },
            ];

            let sign = tools.getMD5Sign(signs);

            let param = {
                // apiPath: apiList.api_packageList, //综合查询接口路径
                groupName: this.pageGroupName, //当前页面表格的标记(自定义字符串)
                sortName: this.sortName, //综合查询接口路径
                sortObject: JSON.stringify(sortObject),
                sign: sign,
            };
            Api.pushFindFieldSort(param).then((res) => {
                this.$message.success(res.data.message || "操作成功");
                this.getGroupFindFieldSort();
            });
            this.show_sortTableHeard = false; //关闭弹窗
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            this.show_sortTableHeard = false;
        },
        // 点击某一项
        checktab(idx, e) {
            let that = this;
            that.tabelHeadeTitle[idx].isShow = e;
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
            if (selectLength == that.tabelHeadeTitle.length) {
                that.allCheck = true;
            } else {
                that.allCheck = false;
            }
        },
        //全选
        checkAll(e) {
            let that = this;
            this.allCheck = e;
            for (var j = 0; j < this.tabelHeadeTitle.length; j++) {
                this.tabelHeadeTitle[j].isShow = e;
            }
            let selectLength = 0;
            for (var i = 0; i < that.tabelHeadeTitle.length; i++) {
                if (that.tabelHeadeTitle[i].isShow) {
                    selectLength++;
                }
            }
            that.selectLength = selectLength;
        },
        //关闭弹窗
        closeDias(e) {
            this.show_sortTableHeard1 = e;
        },
        // 合计
        getSummaries(param) {
            let that = this;
            const {
                columns,
                data
            } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = "合计";
                    return;
                }
                const values = data.map((item) => Number(item[column.property]));
                for (var i = 0; i < that.totalArr.length; i++) {
                    if (column.property === that.totalArr[i]) {
                        if (!values.every((value) => isNaN(value))) {
                            sums[index] = values.reduce((prev, curr) => {
                                const value = Number(curr);
                                if (!isNaN(value)) {
                                    return tools.mathToFixed(tools.add(prev, curr), 2)
                                } else {
                                    return prev;
                                }
                            }, 0);
                            sums[index] += "";
                        } else {
                            sums[index] = "---";
                        }
                    }
                }
            });
            this.$nextTick(() => {
                this.$refs.mytable.doLayout();
            });
            return sums;
        },




        //分页
        handleSizeChange(val) {
            this.size = val;
            this.getData();
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.getData();
        },

        // 上一页
        handlePrevPage() {
            this.pageNum = this.pageNum - 1 || 1;
            this.getData();
        },
        // 下一页
        handleNextPage() {
            this.pageNum = this.pageNum + 1 || 1;
            this.getData();
        },


        // 签收记录
        //分页
        handleSizeChangeB(val) {
            this.pageStart = 1;
            this.pageTotal = val;
            this.getSignforRecordList();
        },
        handleCurrentChangeB(val) {
            this.pageStart = val;
            this.getSignforRecordList();
        },
    }
}